import { Button, Box, HStack, Image, Text } from "@chakra-ui/react";

interface Props {
  label: string;
  icon: string;
  primaryCTAText: string;
  primaryCTAUrl: string;
  secondaryCTAText: string;
  secondaryCTAUrl: string;
  tvl: string | undefined;
}

const MobileCard = ({
  label,
  icon,
  primaryCTAText,
  primaryCTAUrl,
  secondaryCTAText,
  secondaryCTAUrl,
  tvl,
}: Props) => {
  return (
    <Box w="full" h={168} borderRadius={16} p={0} bg="#E6F8EF">
      <Box p={4} pos="relative" overflow="hidden" borderRadius={16}>
        <HStack align="center" justify="space-between">
          <Text fontSize={20} fontWeight={600}>
            {label}
          </Text>
          <Image
            pos="absolute"
            top={-2}
            right={-3}
            boxSize="90px"
            src={icon}
            alt={label}
          />
        </HStack>
        <Text textAlign="left" mt={6} textStyle="p16" fontWeight={600}>
          {tvl ? (
            <>
              {tvl} <Text as="span">staked</Text>
            </>
          ) : (
            "Coming soon"
          )}
        </Text>
        <HStack mt={4} gap={2}>
          {secondaryCTAText && (
            <Button as="a" href={secondaryCTAUrl} flex={1} variant="outline">
              {secondaryCTAText}
            </Button>
          )}
          {primaryCTAText && (
            <Button
              as="a"
              href={primaryCTAUrl}
              flex={1}
              bg="primary !important"
            >
              {primaryCTAText}
            </Button>
          )}
        </HStack>
      </Box>
    </Box>
  );
};

export default MobileCard;
