import { Center, Flex, Show, VStack } from "@chakra-ui/react";

// Components
import { Section, NetworkCard, BlockChainInfoType } from "mlp-sdk";
// import NetworkCard from "components/NetworkCard";
import MobileCard from "./components/MobileCard";

// Constants
import { NavItemsType } from "mlp-sdk/constants/types";
import { iconMapping } from "mlp-sdk/constants/icons";

type Props = {
  data: {
    section_details: {
      title: string;
      sub_title: string;
      section_id: string;
    };
  };
  networks: NavItemsType["details"];
  tvl: BlockChainInfoType | undefined;
};

const SupportedNetworks = ({ data, networks, tvl }: Props) => {
  const {
    section_details: { title, sub_title, section_id },
  } = data;

  return (
    <Section title={title} subTitle={sub_title} id={section_id}>
      <Show above="lg">
        <Center>
          <Flex
            maxW="1130px"
            gap={6}
            mt="64px"
            justify="center"
            flexWrap="wrap"
          >
            {networks.map(
              ({
                detailId,
                label,
                imgKey,
                primaryCTAText,
                primaryCTAUrl,
                secondaryCTAText,
                secondaryCTAUrl,
                tag,
              }) => (
                <NetworkCard
                  key={detailId}
                  label={label}
                  icon={iconMapping[imgKey]}
                  tag={tag}
                  primaryCTAText={primaryCTAText as string}
                  primaryCTAUrl={primaryCTAUrl as string}
                  secondaryCTAText={secondaryCTAText as string}
                  secondaryCTAUrl={secondaryCTAUrl as string}
                  tvl={
                    tvl &&
                    (tvl as any)[imgKey] &&
                    Intl.NumberFormat("en-US", {
                      notation: "compact",
                      style: "currency",
                      currency: "USD",
                    }).format((tvl as any)[imgKey]?.usd?.toFixed(0))
                  }
                />
              )
            )}
          </Flex>
        </Center>
      </Show>
      <Show below="lg">
        <VStack px={4} mt={10} justify="stretch">
          {networks.map(
            ({
              id,
              label,
              imgKey,
              primaryCTAText,
              primaryCTAUrl,
              secondaryCTAText,
              secondaryCTAUrl,
            }) => (
              <MobileCard
                key={id}
                label={label}
                icon={iconMapping[imgKey]}
                primaryCTAText={primaryCTAText as string}
                primaryCTAUrl={primaryCTAUrl as string}
                secondaryCTAText={secondaryCTAText as string}
                secondaryCTAUrl={secondaryCTAUrl as string}
                tvl={
                  tvl &&
                  (tvl as any)[imgKey] &&
                  Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                  }).format((tvl as any)[imgKey]?.usd?.toFixed(0))
                }
              />
            )
          )}
        </VStack>
      </Show>
    </Section>
  );
};

export default SupportedNetworks;
