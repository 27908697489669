export const FAQSchema = {
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is Stader?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Stader is a non-custodial, smart contract-driven staking platform that facilitates easy discovery and utilization of staking solutions. It acts as an essential staking middleware infrastructure for various Proof-of-Stake (PoS) networks, catering to retail cryptocurrency users, exchanges, and custodians.",
      },
    },
    {
      "@type": "Question",
      name: "What is liquid staking?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Liquid staking is a mechanism within the decentralized finance (DeFi) space that allows users to stake their cryptocurrency to earn rewards while retaining liquidity of the staked assets. Unlike traditional staking, where the staked funds are locked and inaccessible for a defined period, liquid staking provides a tokenized version of the staked assets. This means users can still buy, sell, trade, or use those assets, making them more flexible and convenient. Liquid staking offers the dual benefit of better rewards through staking and the ability to continue utilizing the staked funds in other DeFi opportunities.",
      },
    },
    {
      "@type": "Question",
      name: "What are the benefits of liquid staking?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Staking rewards: Liquid staking enables you to get auto-compounded rewards from staking your crypto tokens anytime. Enhanced liquidity: You can access staking rewards anytime by converting your tokens into liquid tokens, avoiding lock-up periods. You can freely trade or use these liquid tokens in DeFi protocols and unlock additional rewards on top of staking rewards. Liquid staking is a great option to enjoy flexibility and get staking rewards without locking up your tokens.",
      },
    },
    {
      "@type": "Question",
      name: "Which cryptocurrencies can be used for liquid staking?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Tokens eligible for Liquid Staking are the native tokens of Proof-of-Stake blockchains. Stader is available on some of the most popular ones, such as Ethereum, Polygon, Hedera, BNB, Fantom, Near, and Terra 2.0.",
      },
    },
    {
      "@type": "Question",
      name: "How much rewards can I get through liquid staking?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Liquid staking enables you to access your staked tokens without locking them up. The potential rewards achievable through liquid staking may vary from one platform to another and from one network to another. To know more about the rewards you can get for each blockchain on Stader, you can visit that respective blockchain’s dApp, where you can view the staking rewards percentage and the additional DeFi options available for that particular blockchain.",
      },
    },
    {
      "@type": "Question",
      name: "What are the risks associated with liquid staking?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The most common risks associated with liquid staking include: Network risk: Risks related to the specific protocols and networks used in liquid staking can influence the safety and reliability of the staking process. Market risk and volatility: The value of liquid tokens can fluctuate based on market conditions, affecting the overall rewards and liquidity.  Smart contract risk: Liquid staking relies on smart contracts. These could be vulnerable to hacking or technical issues in certain unlikely scenarios.  It is important to measure the risk-reward aspect of liquid staking before deciding whether to use it. If you are considering liquid staking, it’s important to choose a reputable platform and check the security measures deployed by them.",
      },
    },
    {
      "@type": "Question",
      name: "Is liquid staking secure?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Liquid staking carries security risks such as smart contract vulnerabilities, market volatility, and technical and protocol risks. Before participating, users should conduct thorough research, understand the risks, and review security audits to ensure a safe staking experience. A reliable staking provider should implement proactive security measures, including on-chain monitoring, to safeguard your digital assets.  For instance, at Stader, we prioritize the safety and security of your liquid staking tokens by implementing comprehensive measures such as security best practices and multisignature (multisigs) systems. We have undergone rigorous audits by premier security firms, including Sigma Prime, Halborn, Peckshield, Code4rena, Ottersec, and Certik. Additionally, we have established on-chain monitoring with Forta and have orchestrated a bug-bounty program in partnership with Immunefi. Furthermore, we have maintained the security of multiple liquid tokens, which have been live for over 18 months, reinforcing our commitment to robust protection.",
      },
    },
    {
      "@type": "Question",
      name: "How can I get started with liquid staking? What are the steps involved?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "The end-to-end steps in the liquid staking process are as follows: Step 1: Transfer your crypto tokens to any preferred wallet such as Metamask, Ledger, Coinbase Wallet, etc. Step 2: Select your preferred liquid staking platform, such as Stader Labs, and the network/blockchain’s native asset that you want to stake in, such as Ethereum, Polygon, Binance, etc. Step 3: Connect your wallet to the platform and enter any amount of tokens that you are interested in staking. You will be able to view the estimated liquid tokens that your stake amounts to and the associated gas fee of the transaction. Step 4: Once you confirm the transaction, the liquid staking provider’s smart contract will stake your assets on your behalf and issue their liquid tokens in return to you.  Step 5: You are now eligible to receive staking rewards, as well as use the issued liquid tokens for trading, lending, borrowing, liquidity mining, and other DeFi activities for additional rewards. Liquid staking is a convenient and flexible way to get staking rewards on your crypto tokens. It gives you access to stake your assets without locking them up, which can be used in various ways.",
      },
    },
    {
      "@type": "Question",
      name: "How can I unstake my liquid staking tokens?",
      acceptedAnswer: {
        "@type": "Answer",
        text: 'To unstake your liquid staking tokens, you typically need to follow these steps: Step 1: Access the dApp platform where you have initially staked your tokens.  Step 2: Look for the "Unstake" option to redeem your underlying tokens. Choose the amount of tokens you wish to unstake. Some platforms may allow you to unstake all your tokens simultaneously, while others may require you to unstake in smaller increments. Stader allows you to unstake your tokens at once.  Step 3: Confirm your request to unstake the chosen amount of tokens. Depending on the network/blockchain, a waiting period might be known as the "unbonding period" before you can fully withdraw your tokens.  This period is set to ensure the network\'s security and varies depending on the blockchain. Checking the transaction [gas] fees before unstaking your liquid tokens is also beneficial.  Step 4: Allow the platform to complete the unstaking process. During the unbonding period, your staked tokens won’t be immediately available for withdrawal, but you will receive them after the period elapses. Because in the unbonding time, your staked tokens are locked and inaccessible after initiating the unstaking process. Step 5: Once the unbonding period ends, the staked tokens will be released, and you can go to the "Withdraw" option on Stader.  You can also go to one of the Decentralized Exchanges (DEXes), where a Liquidity Pool for the LST and the underlying asset has been set. You could immediately swap your liquid staked tokens for your native asset without delay or an unbonding period.  You will have to check the depth of the liquidity and the price impact of your swap, as well as consider gas fees and the time it takes in each chain to validate the transaction.  Remember that the steps and options for unstaking may vary depending on the blockchain and liquidity provider you use for liquid staking. Always review the platform\'s guidelines and user instructions for a smooth unstaking process.',
      },
    },
  ],
};

export const webPageSchema = {
  "@context": "http://schema.org",
  "@type": "WebPage",
  name: "Stader Labs - Liquid Staking Platform",
  description:
    "Stader Labs is a liquid staking platform for earnings liquid staking rewards across crypto tokens such as Ethereum, polygon, BNB, Hedera, & more. Use the liquid tokens for earning more rewards across a growing ecosystem of Defi integrations with Stader Labs.",
  url: "https://www.staderlabs.com",
  breadcrumb: {
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://www.staderlabs.com",
      },
    ],
  },
  publisher: {
    "@type": "Organization",
    name: "Stader Labs",
    logo: {
      "@type": "ImageObject",
      url: "https://www.staderlabs.com/assets/images/stader-icon.svg",
    },
  },
};

export const organisationSchema = {
  "@context": "http://schema.org",
  "@type": "Organization",
  name: "Stader Labs",
  url: "https://www.staderlabs.com/",
  logo: "https://www.staderlabs.com/assets/images/stader-icon.svg",
  contactPoint: [
    {
      "@type": "ContactPoint",
      telephone: "+91-8800690696",
      contactType: "customer service",
    },
    {
      "@type": "ContactPoint",
      email: "contact@staderlabs.com",
      contactType: "sales",
    },
  ],
  sameAs: [
    "https://twitter.com/staderlabs",
    "https://discord.com/invite/xJURAhSmav",
    "https://www.reddit.com/r/StaderLabs_Official/",
    "https://t.me/staderlabs",
  ],
  description:
    "Stader Labs is a liquid staking platform for earnings liquid staking rewards across crypto tokens such as Ethereum, polygon, BNB, Hedera, & more. Use the liquid tokens for earning more rewards across a growing ecosystem of Defi integrations with Stader Labs.",
};

export const logoSchema = {
  "@context": "https://schema.org",
  "@type": "Logo",
  url: "https://www.staderlabs.com/assets/images/stader-icon.svg",
  width: 200,
  height: 50,
};
