import { useContext, useEffect, useMemo } from "react";
import { NextPage } from "next";

// Components
import Hero from "components/Hero";
import How from "components/How";
import SupportedNetworks from "components/SupportedNetworks";
import Security from "components/Security";
import Partners from "components/Partners";
import FAQs from "@components/FAQ";
import FAQsContent from "@components/FAQContent";
import {
  Header,
  Footer,
  PageContext,
  Newsletter,
  useStaderTVL,
  Blogs,
} from "mlp-sdk";

// Providers
import { PageProvider } from "providers/PageProvider";

// Utils
import {
  // fetchPageData,
  getSectionDataByKey,
  prepareNavData,
} from "mlp-sdk/utils/common";
import { trackEvent } from "mlp-sdk/utils/firebase";
import PageHead from "@components/PageHead/PageHead";
import navData from "@constants/navData.json";
import pageData from "@constants/pageData.json";
import SdUtilityEntryPoint from "@components/SdUtilityEntryPoint";

const Home = () => {
  const { page } = useContext(PageContext);
  const { tvl } = useStaderTVL();

  const { navData, networks } = useMemo(
    () => prepareNavData(page.data.navData, tvl),
    [page.data.navData, tvl]
  );
  const pageData = page.data.pageData;

  const heroData = pageData.hero;
  const bannerData = pageData.banner;

  const howItWorksData = getSectionDataByKey(pageData, "how_it_works");
  const faqsData = getSectionDataByKey(pageData, "faqs");
  const faqsKnowMoreData = getSectionDataByKey(pageData, "know-more-content");
  const supportedNetworksData = getSectionDataByKey(pageData, "networks");
  const securityData = getSectionDataByKey(pageData, "security");
  const partnerData = getSectionDataByKey(pageData, "partners");
  const blogsData = getSectionDataByKey(pageData, "blogs");
  const subscriptionData = getSectionDataByKey(pageData, "subscription");
  // const sdUtilityEntryPointData = getSectionDataByKey(
  //   pageData,
  //   "sd_utility_entry_point"
  // );

  const sdUtilityEntryPointData = {
    section_details: {
      title: "SD Utility Pool",
      sub_title:
        "Contribute to Ethereum decentralisation by delegating your $SD to the Pool and receive double digit rewards!",
      cta_text: "Delegate Now",
      cta_link: "sd/utility_pool/",
      section_id: "sd_utility_entry_point",
    },
    img_key: "sdUtilEntryPoint",
  };

  useEffect(() => {
    const navItems = pageData.sections.map(
      (section: any) =>
        section?.section_details?.section_id ||
        section?.sectionDetails?.section_id
    );

    const mainElements = navItems.map((id: string) =>
      document.getElementById(id)
    );

    const observer = new IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            trackEvent(`VIEW_${entry.target.id}`);
          }
        }
      },
      {
        threshold: 0.5,
      }
    );

    mainElements.forEach(
      (element: Element) => element && observer.observe(element)
    );

    // cleanup function
    return () => {
      mainElements.forEach(
        (element: Element) => element && observer.unobserve(element)
      );
    };
  }, [pageData.sections]);

  return (
    <>
      <PageHead page="index" />
      <Header
        data={navData}
        networks={networks}
        banner={bannerData}
        tvl={tvl}
      />
      <Hero data={heroData} tvl={tvl} />
      <SupportedNetworks
        data={supportedNetworksData}
        networks={networks}
        tvl={tvl}
      />
      <SdUtilityEntryPoint data={sdUtilityEntryPointData} />
      <How data={howItWorksData} />
      <Security data={securityData} />
      <Partners data={partnerData} />
      <Blogs data={blogsData} />
      <Newsletter data={subscriptionData} networks={networks} />
      <FAQs data={faqsData} />
      {faqsKnowMoreData && <FAQsContent data={faqsKnowMoreData} />}
      <Footer data={navData} />
    </>
  );
};

const HomePage: NextPage = () => {
  return (
    <PageProvider>
      <Home />
    </PageProvider>
  );
};

export async function getStaticProps() {
  return {
    props: {
      page: {
        data: {
          navData,
          pageData,
        },
        error: { navError: null, pageError: null },
      },
    },
  };
}

export default HomePage;
