import {
  Box,
  Center,
  Flex,
  HStack,
  Image,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Button,
} from "@chakra-ui/react";

// Components
import { Section } from "mlp-sdk";

// Constants
import { iconMapping } from "mlp-sdk/constants/icons";
import { LP_PARTNERS } from "mlp-sdk/constants/firebase";
import { trackEvent } from "mlp-sdk/utils/firebase";

type Props = {
  data: {
    sectionDetails: {
      title: string;
      section_id: string;
    };
    data: {
      sectionId: string;
      label: string;
      ctaText: string;
      link: string;
      partners: {
        imgKey: string;
        link: string;
      }[];
    }[];
  };
};

const Partners = ({ data }: Props) => {
  const { sectionDetails, data: partnerData } = data;
  const { title, section_id } = sectionDetails;

  const tabsMap = partnerData.reduce(
    (acc: any, curr: any) => acc.set(curr.sectionId, curr),
    new Map()
  );
  const tabsArray = Array.from(tabsMap.entries());
  const tabs = tabsArray.map((item: any) => item[0]);

  return (
    <Box>
      <Section title={title} id={section_id}>
        <Center
          bg="#E6F8EF"
          w="full"
          h={{ base: "inherit", lg: "364px" }}
          mt={10}
          borderRadius={{ base: 16, lg: 40 }}
        >
          <Tabs
            mt={{ base: 7, lg: 0 }}
            variant="unstyled"
            maxW="fit-content"
            align="center"
          >
            <TabList
              bg="canvas"
              borderRadius={20}
              border="1px solid"
              borderColor="divider"
              p="3px"
              h={"52px"}
              maxW="fit-content"
            >
              {tabs.map((tab) => {
                const tabLabel = tabsMap.get(tab).label;
                return (
                  <Tab
                    key={tab}
                    fontSize={{ base: 14, lg: 24 }}
                    fontWeight={{ base: 600, lg: 700 }}
                    borderRadius={16}
                    p={{ base: "12px 16px", lg: "12px 24px" }}
                    _selected={{ bg: "primary", color: "white" }}
                  >
                    {tabLabel}
                  </Tab>
                );
              })}
            </TabList>
            <TabPanels>
              {tabs.map((tab) => {
                const { partners, ctaText, link } = tabsMap.get(tab);
                return (
                  <TabPanel
                    key={`tabPanel-${tab}`}
                    py={0}
                    px={{ base: 0, lg: 4 }}
                  >
                    <Flex
                      mt={{ base: 7, lg: 10 }}
                      justify={{ base: "space-between", lg: "center" }}
                      gap="1rem"
                      flexWrap="wrap"
                    >
                      {partners.map(
                        ({
                          imgKey,
                          label,
                        }: {
                          imgKey: string;
                          label: string;
                        }) => (
                          <HStack
                            key={label}
                            w={{
                              base: "full",
                              lg: partners?.length > 6 ? 266 : 360,
                            }}
                            h={100}
                            gap={4}
                            p={6}
                          >
                            <Image
                              src={iconMapping[imgKey]}
                              alt={label}
                              h="48px"
                              w="48px"
                            />
                            <Box>
                              <Text
                                fontSize="20px"
                                lineHeight="24px"
                                fontWeight={600}
                                textAlign="left"
                              >
                                {label}
                              </Text>
                            </Box>
                          </HStack>
                        )
                      )}
                      {ctaText && (
                        <Center
                          w={{ base: 328, lg: 360 }}
                          h={100}
                          p={6}
                          justifyContent="flex-start"
                        >
                          <Button
                            as="a"
                            href={link}
                            variant="outline"
                            w={201}
                            h={58}
                            onClick={() => {
                              trackEvent(LP_PARTNERS, {
                                cta_text: ctaText,
                                cta_link: link,
                              });
                            }}
                          >
                            {ctaText}
                          </Button>
                        </Center>
                      )}
                    </Flex>
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </Center>
      </Section>
    </Box>
  );
};

export default Partners;
