import { useMemo, useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Button,
  Center,
} from "@chakra-ui/react";

import { Section } from "mlp-sdk";

type Props = {
  data: {
    section_details: {
      title: string;
      section_id: string;
      cta_text?: string;
    };
    faqs: {
      question: string;
      answer: any;
    }[];
  };
};

const FAQs = ({ data }: Props) => {
  const { section_details, faqs } = data;
  const [isViewMoreSelected, setIsViewMoreSelected] = useState(false);

  const updatedFAQs = useMemo(() => {
    return isViewMoreSelected ? faqs : faqs.slice(0, 3);
  }, [faqs, isViewMoreSelected]);

  return (
    <Section isH2 title={section_details.title} id={section_details.section_id}>
      <Center flexDir="column">
        <Accordion
          allowToggle
          w="100%"
          maxW="786px"
          mt={{ base: "24px", lg: "40px" }}
        >
          {updatedFAQs.map((item, index: number) => (
            <AccordionItem
              border="0px"
              bg="canvas"
              mt={index > 0 ? "1rem" : 0}
              key={item.question}
              borderRadius={{ base: "4px", lg: "8px" }}
              textAlign="left"
            >
              <h3>
                <AccordionButton
                  p={{ base: "12px 1rem", lg: "1.5rem 2rem" }}
                  _expanded={{
                    pb: { base: "8px", lg: "12px" },
                  }}
                >
                  <Box
                    as="span"
                    flex="1"
                    textAlign="left"
                    fontWeight="600"
                    fontSize={{ base: "14px", lg: "16px" }}
                    lineHeight={{ base: "20px", lg: "24px" }}
                    color="textPrimary"
                  >
                    {item.question}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h3>
              <AccordionPanel
                pb={{ base: "12px", lg: "24px" }}
                pl={{ base: "16px", lg: "32px" }}
                pr={{ base: "44px", lg: "68px" }}
              >
                <div>
                  <Box
                    color="#3F6870 !important"
                    dangerouslySetInnerHTML={{ __html: item.answer }}
                    fontWeight="500"
                    fontSize={{ base: "10px", lg: "12px" }}
                    lineHeight={{ base: "14px", lg: "18px" }}
                    sx={{
                      "& ol": {
                        pl: { base: 2, lg: 4 },
                      },
                      "& ul": {
                        pl: { base: 2, lg: 4 },
                      },
                    }}
                    className="faqAnswer"
                  />
                </div>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>

        {updatedFAQs.length !== faqs.length && section_details.cta_text ? (
          <Button
            mt={{ base: "1.5rem", lg: "2.5rem" }}
            variant="outline"
            size="xl"
            w={{ base: "157px", lg: "201px" }}
            h={{ base: "40px", lg: "58px" }}
            onClick={() => {
              setIsViewMoreSelected(!isViewMoreSelected);
            }}
            fontSize={{ base: "14px", lg: "16px" }}
            lineHeight={{ base: "20px", lg: "24px" }}
            fontWeight="600"
          >
            {section_details.cta_text}
          </Button>
        ) : null}
      </Center>
    </Section>
  );
};

export default FAQs;
