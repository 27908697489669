import {
  Box,
  Center,
  Flex,
  Image,
  Text,
  Button,
  Container,
  Show,
  Stack,
} from "@chakra-ui/react";
import { imageMapping } from "mlp-sdk/constants/images";

// Components
import { LP_SD_ENTRY_POINT, trackEvent } from "mlp-sdk";

type Props = {
  data: {
    section_details: {
      title: string;
      sub_title: string;
      section_id: string;
      cta_text: string;
      cta_link: string;
    };
    img_key: string;
  };
};

const SdUtilityEntryPoint = ({ data }: Props) => {
  const { section_details, img_key } = data;
  const {
    title = "",
    sub_title = "",
    cta_text = "",
    cta_link = "",
  } = section_details;

  return (
    <Box
      w={"full"}
      py={{
        lg: "80px",
        base: "16px",
      }}
    >
      <Container overflow={"hidden"}>
        <Stack
          paddingBottom={{
            base: "196px",
            md: 0,
          }}
          spacing={0}
          zIndex={0}
          position={"relative"}
          borderRadius={{ base: 16, lg: 40 }}
          bg="#E6F8EF"
          overflow={"hidden"}
        >
          <Flex
            position={"relative"}
            zIndex={2}
            padding={{
              base: "1rem",
              lg: "5rem",
            }}
            paddingBottom={{
              md: "5rem",
            }}
            overflow={"hidden"}
          >
            <Center
              maxW={"563px"}
              alignItems={"flex-start"}
              flexDirection={"column"}
            >
              {title && (
                <Text as={"p"} textStyle={{ base: "h3", md: "h1" }}>
                  {title}
                </Text>
              )}
              {sub_title && (
                <Text
                  fontSize={{
                    md: "20px",
                    base: "12px",
                  }}
                  maxWidth={{
                    base: "453px",
                    lg: "initial",
                  }}
                  color={"#3F6870"}
                  fontWeight={500}
                >
                  {sub_title}
                </Text>
              )}
              <Button
                onClick={() => {
                  trackEvent(LP_SD_ENTRY_POINT, {
                    type: "sd_entry_point",
                    cta: cta_text,
                    link: cta_link,
                  });
                }}
                as={"a"}
                href={cta_link}
                sx={{
                  marginTop: {
                    base: "1.5rem",
                    lg: "3rem",
                  },
                }}
                p="1.5rem"
                h={46}
              >
                <Text>{cta_text}</Text>
              </Button>
              {/* <Image src={imageMapping.} alt="SD utlity" /> */}
            </Center>
          </Flex>
          <Show above={"md"}>
            <Image
              alt="SD Utility APY"
              objectFit={"cover"}
              zIndex={1}
              position={"absolute"}
              top={0}
              right={"-5px"}
              height={"100%"}
              width={"100%"}
              src={imageMapping[img_key].web}
            />
          </Show>
          <Show below={"md"}>
            <Center>
              <Image
                alt="SD Utility APY"
                position={"absolute"}
                bottom={"-5px"}
                src={imageMapping[img_key].mobile}
              />
            </Center>
          </Show>
        </Stack>
      </Container>
    </Box>
  );
};

export default SdUtilityEntryPoint;
