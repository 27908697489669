import Head from "next/head";
import navData from "@constants/navData.json";
import {
  FAQSchema,
  logoSchema,
  organisationSchema,
  webPageSchema,
} from "@constants/JSONScript";

interface PageHeadProps {
  page: string;
  children?: any;
}

const getPageData = (page: string) => {
  if (navData?.seo_header && navData.seo_header.length > 0) {
    let data: any = null;
    navData.seo_header.forEach((item: any) => {
      if (item.page === page) {
        data = item;
      }
    });
    return data;
  }
  return null;
};

const PageHead = ({ children, page }: PageHeadProps) => {
  const data = getPageData(page);
  if (data) {
    return (
      <>
        <Head>
          {page === "index" && (
            <>
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(FAQSchema),
                }}
              />
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(organisationSchema),
                }}
              />
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(webPageSchema),
                }}
              />
              <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(logoSchema),
                }}
              />
            </>
          )}
          <link rel="icon" href="/favicon.ico" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {data.title && <title>{data.title}</title>}
          {data.description && (
            <meta name="description" content={data.description} />
          )}
          {data.keywords && <meta name="keywords" content={data.keywords} />}
          {data.title && <meta property="og:title" content={data.title} />}
          {data.description && (
            <meta property="og:description" content={data.description} />
          )}
          {data.robots && <meta name="robots" content={data.robots} />}
          {data.canonical_url && (
            <link rel="canonical" href={data.canonical_url} />
          )}
        </Head>
        {children}
      </>
    );
  }
  return null;
};

export default PageHead;
