import { Box, Flex, Text, VStack, Image } from "@chakra-ui/react";

// Components
import { Section } from "mlp-sdk";

// constants
import { imageMapping } from "mlp-sdk/constants/images";

type Props = {
  data: {
    section_details: {
      title: string;
      section_id: string;
    };
    worksData: {
      title: string;
      description: string;
      img_key: string;
    }[];
  };
};

const How = ({ data }: Props) => {
  const { section_details, worksData } = data;
  const { title, section_id } = section_details;

  return (
    <Box py={{ base: "24px", lg: "64px" }} pb={{ base: 0, lg: "inherit" }}>
      <Section title={title} id={section_id} pt={{ base: 10, lg: "inherit" }}>
        <Flex
          mt={{ base: 12, lg: 10 }}
          flexDir={{ base: "column", lg: "row" }}
          justify="center"
          gap={{ base: "48px", lg: "56px" }}
        >
          {worksData.map(({ title, description, img_key }) => (
            <VStack
              key={description}
              w={{ base: "auto", lg: "382" }}
              h={{ base: "inherit", lg: 364 }}
              gap={6}
              p={{ base: 0, lg: "24px 0" }}
            >
              <Image
                w={{ base: 256, lg: 240 }}
                h={{ base: 192, lg: 172 }}
                src={imageMapping[img_key]}
                alt={title}
              />

              <Box w={{ base: 280, lg: 344 }} mt="0px">
                <Text
                  textStyle={{ base: "h5", lg: "h3" }}
                  fontWeight="600 !important"
                >
                  {title}
                </Text>
                <Text mt={1} textStyle={{ base: "p12", lg: "p14" }}>
                  {description}
                </Text>
              </Box>
            </VStack>
          ))}
        </Flex>
      </Section>
    </Box>
  );
};

export default How;
