import { Box, Image, Flex, Button } from "@chakra-ui/react";

// Components
import { Section } from "mlp-sdk";

import { isExternalLink } from "mlp-sdk/utils/common";
import { trackEvent } from "mlp-sdk/utils/firebase";

import { imageMapping } from "mlp-sdk/constants/images";
import { LP_AUDIT_REPORTS } from "mlp-sdk/constants/firebase";

type Props = {
  data: {
    sectionDetails: {
      title: string;
      sub_title: string;
      section_id: string;
      cta_text: string;
      cta_link: string;
    };
    img_key: string;
  };
};

const Security = ({ data }: Props) => {
  const { sectionDetails, img_key } = data;
  const { title, sub_title, section_id, cta_text, cta_link } = sectionDetails;

  return (
    <Box>
      <Section title={title} subTitle={sub_title} id={section_id}>
        <Button
          as="a"
          href={cta_link}
          target={isExternalLink(cta_link) ? "_blank" : "self"}
          mt={{ base: 6, lg: 10 }}
          variant="outline"
          size={{ base: "lg", lg: "xl" }}
          onClick={() => {
            trackEvent(LP_AUDIT_REPORTS, {
              cta: cta_text,
              link: cta_link,
            });
          }}
        >
          {cta_text}
        </Button>
        <Flex px={4} mt="80px" justify="center">
          <Box
            h={{ base: 200, lg: 449 }}
            overflow="hidden"
            w={{ base: 328, lg: 738 }}
          >
            <Image src={imageMapping[img_key]} alt="security" />
          </Box>
        </Flex>
      </Section>
    </Box>
  );
};

export default Security;
