import {
  Box,
  Button,
  Center,
  Container,
  HStack,
  Image,
  Show,
  StackDivider,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import Skeleton from "react-loading-skeleton";

// Constants
import {
  imageMapping,
  largeImageMapping,
  mobileImageMapping,
  BlockChainInfoType,
} from "mlp-sdk";
import { LP_START_STAKING } from "mlp-sdk/constants/firebase";

// utils
import { trackEvent } from "mlp-sdk/utils/firebase";

type Props = {
  data: {
    title: string;
    subTitle: string;
    imgKey: string;
    ctaText: string;
    ctaLink: string;
    imgDisclaimer: string;
    statistics: {
      title: string;
      key: string;
    }[];
  };
  tvl: BlockChainInfoType | undefined;
};

const Hero = ({ data, tvl }: Props) => {
  const { title, subTitle, imgKey, ctaText, ctaLink, statistics } = data;

  const [isLargerThan1512] = useMediaQuery("(min-width: 1513px)");

  const imgMapping = isLargerThan1512 ? largeImageMapping : imageMapping;

  return (
    <>
      <Show above="lg">
        <Box
          bgImage={`url(${imgMapping[imgKey]})`}
          bgSize="contain"
          bgPosition="center center"
          bgRepeat="no-repeat"
          w="100vw"
          h={752}
        >
          <Container display="flex" h="full">
            <VStack mt="112px">
              <Box>
                <Text textStyle="h1" fontSize={54} maxW={550}>
                  {title}
                </Text>
                <Text
                  textStyle="p14"
                  fontWeight={500}
                  color="textSecondary"
                  mt={3}
                >
                  {subTitle}
                </Text>
                <HStack
                  mt={10}
                  spacing={10}
                  divider={
                    <StackDivider borderColor="divider" borderRadius={2} />
                  }
                >
                  {statistics.map(({ key, title }) => (
                    <Box key={key} textAlign="center">
                      <Text
                        textStyle="l12"
                        color="textSecondary"
                        textTransform="uppercase"
                      >
                        {title}
                      </Text>
                      <Text textStyle="h3" mt={2}>
                        {key?.includes("+") ? (
                          tvl ? (
                            key
                          ) : (
                            <Skeleton />
                          )
                        ) : !tvl ? (
                          <Skeleton />
                        ) : (
                          Intl.NumberFormat("en-US", {
                            notation: "compact",
                            style: "currency",
                            currency: "USD",
                          }).format((tvl as any)[key]?.toFixed(0))
                        )}
                      </Text>
                    </Box>
                  ))}
                </HStack>
                <Button
                  as="a"
                  href={ctaLink}
                  rel="canonical"
                  mt={10}
                  size="xl"
                  minW="fit-content !important"
                  p="16px 32px"
                  onClick={() => {
                    trackEvent(LP_START_STAKING, {
                      type: "hero",
                      cta: ctaText,
                      link: ctaLink,
                    });
                  }}
                >
                  {ctaText}
                </Button>
              </Box>
            </VStack>
          </Container>
        </Box>
      </Show>
      <Show below="lg">
        <Center flexDir="column">
          <Image
            w="100vw"
            h={284}
            bgSize="cover"
            src={mobileImageMapping[imgKey]}
            alt="hero-mobile"
          />
          <Box py={6} px={4} textAlign="center">
            <Text textStyle="h2" maxW={550}>
              {title}
            </Text>
            <Text mt={3} textStyle="p14" fontWeight={500} color="textSecondary">
              {subTitle}
            </Text>
            <HStack
              mt={10}
              spacing={8}
              justify="space-between"
              divider={
                <StackDivider
                  borderColor="textPrimary"
                  borderRadius={2}
                  opacity={0.1}
                />
              }
            >
              {statistics.map(({ key, title }) => (
                <Box key={key} textAlign="center">
                  <Text
                    textStyle="l10"
                    color="textSecondary"
                    textTransform="uppercase"
                  >
                    {title}
                  </Text>
                  <Text textStyle="h5" mt={1}>
                    {key?.includes("+") ? (
                      tvl ? (
                        key
                      ) : (
                        <Skeleton />
                      )
                    ) : !tvl ? (
                      <Skeleton />
                    ) : (
                      Intl.NumberFormat("en-US", {
                        notation: "compact",
                        style: "currency",
                        currency: "USD",
                      }).format((tvl as any)[key]?.toFixed(0))
                    )}
                  </Text>
                </Box>
              ))}
            </HStack>
            <Button as="a" href={ctaLink} mt={10} size="xl" w="full">
              {ctaText}
            </Button>
          </Box>
        </Center>
      </Show>
    </>
  );
};

export default Hero;
